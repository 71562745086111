import React from "react";
import styled from "@emotion/styled";
import Layout from "../components/Layout/Layout";
import SEO from "../components/seo";
import PostHeader from "../components/PostSections/PostHeader";
import GetInvolved from "../components/PageSections/GetInvolved";
import PostContent from "../components/PostContent";

const Content = styled.div``;

const IntroText = styled.section`
  strong {
    font-weight: 600;
  }

  p {
    font-size: 18px;
    line-height: 27px;
    letter-spacing: -0.46px;
    margin-bottom: 34px;
  }

  @media (min-width: 768px) {
    p {
      font-size: 22px;
      line-height: 34px;
      letter-spacing: -0.56px;
      margin-bottom: 38px;
    }
  }

  @media (min-width: 1024px) {
    p {
      font-size: 27px;
      line-height: 38px;
      letter-spacing: -0.68px;
    }
  }
`;

const Post = ({ pageContext }) => {
  const {
    title,
    date,
    acf,
    content,
    yoast_meta,
    featured_media
  } = pageContext.node;
  const image = featured_media?.source_url;
  const {
    yoast_wpseo_metadesc: seo_desc,
    yoast_wpseo_facebook_title: seo_fb_title,
    yoast_wpseo_facebook_description: seo_fb_desc,
    yoast_wpseo_twitter_title: seo_tw_title,
    yoast_wpseo_twitter_description: seo_tw_desc
  } = yoast_meta;

  const seo_image =
    yoast_meta.yoast_wpseo_social_defaults?.og_frontpage_image?.source_url ||
    "";
  const seo_fb_image =
    yoast_meta.yoast_wpseo_facebook_image?.source_url || seo_image;
  const seo_tw_image =
    yoast_meta.yoast_wpseo_twitter_image?.source_url || seo_image;

  return (
    <Layout>
      <SEO
        title={title}
        description={seo_desc}
        image={seo_fb_image}
        metaTitleFacebook={seo_fb_title}
        metaImageFacebook={seo_fb_image}
        metaDescFacebook={seo_fb_desc}
        metaTitleTwitter={seo_tw_title}
        metaImageTwitter={seo_tw_image}
        metaDescTwitter={seo_tw_desc}
      />
      <PostHeader
        title={title}
        age={acf?.age}
        snippet={acf?.snippet}
        image={acf?.image}
        date={date}
      />
      <PostContent>
        <IntroText dangerouslySetInnerHTML={{ __html: acf?.intro_text }} />
        <Content dangerouslySetInnerHTML={{ __html: content }} />
      </PostContent>
      <GetInvolved />
    </Layout>
  );
};
export default Post;
