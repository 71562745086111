import React from "react";
import { styled } from "twin.macro";

const Header = styled.section`
  border-bottom: 1px solid rgba(124, 135, 149, 0.3);
  position: relative;

  @media (min-width: 1024px) {
    border-bottom: 0;
  }
`;

const InnerContent = styled.div`
  text-align: center;
  padding: 40px 75px;

  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    text-align: left;
    padding: 60px 64px;
  }

  @media (min-width: 1024px) {
    max-width: 1260px;
    padding: 90px;
    margin: 0 auto;
  }
`;

const Background = styled.div`
  background-color: var(--color-offWhite);
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  height: 213px;

  @media (min-width: 768px) {
    height: auto;
    right: auto;
    bottom: 0;
    width: 71.09375%;
  }

  @media (min-width: 1024px) {
    width: 67.5%;
  }
`;

const HeaderContent = styled.aside`
  @media (min-width: 768px) {
    width: 50%;
  }
`;

const PostImage = styled.div`
  width: 100%;
  background-image: ${props => `url(${props.bg})`};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  img {
    /* display: none; */
    height: 0;
    visibility: hidden;
  }

  margin-bottom: 30px;

  @media (min-width: 768px) {
    order: 2;
    margin-bottom: 0;
    width: 41.66666667%;
  }

  @media (min-width: 1024px) {
    max-width: 415px;
  }
`;

const PostTitle = styled.h1`
  font-weight: 600;

  font-size: 40px;
  line-height: 48px;
  letter-spacing: -1.83px;

  @media (min-width: 768px) {
    padding-top: 10px;
    font-size: 55px;
    line-height: 55px;
    letter-spacing: -2.51px;
    margin-bottom: 10px;
  }

  @media (min-width: 1024px) {
    padding-top: 30px;

    font-size: 90px;
    line-height: 90px;
    letter-spacing: -4.11px;
    margin-bottom: 15px;
  }
`;

const Age = styled.p`
  font-weight: 600;

  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.17px;
  margin-bottom: 7px;

  @media (min-width: 768px) {
    font-size: 28px;
    line-height: 34px;
    letter-spacing: -0.71px;
    margin-bottom: 10px;
  }

  @media (min-width: 1024px) {
    font-size: 38px;
    line-height: 48px;
    letter-spacing: -1.57px;
    margin-bottom: 15px;
  }
`;

const Description = styled.p`
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.27px;
  margin-bottom: 7px;

  @media (min-width: 768px) {
    font-size: 19px;
    line-height: 29px;
    letter-spacing: -0.36px;
    margin-bottom: 20px;
    max-width: 25ch;
  }

  @media (min-width: 1024px) {
    font-size: 22px;
    line-height: 34px;
    letter-spacing: -0.41px;
    max-width: 32ch;
    margin-bottom: 60px;
  }
`;

const PostHeader = ({ title, age, snippet, image }) => {

  return (
    <Header>
      <Background />
      <InnerContent>
        <PostImage bg={image?.source_url || image?.localFile?.url} />
        <HeaderContent>
          <PostTitle>{title}</PostTitle>
          <Age>{age}</Age>
          <Description>{snippet}</Description>
        </HeaderContent>
      </InnerContent>
    </Header>
  );
};

export default PostHeader;
